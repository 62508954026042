// plugins/tripsPlugin.js

export default {
    namespaced: true,
    actions: {
      // Tus acciones relacionadas con los empresas
      // async get_all_Trips(_,{Authtoken, userCompany}) {
      //   try {

      //     const API_URL = `${this.state.base_URL}trips`;
      //     // console.log('userType: ', userType)

      //     // Create headers object with common headers
      //     const headers = {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json',
      //       'Authorization': `Bearer ${Authtoken}`,
      //       'User-Company': userCompany
      //     };
      
      //     const res = await fetch(API_URL, {
      //       method: 'GET',
      //       headers: headers
      //     });
      
      //     const response = await res.json();
      
      //     // console.log('Response from server:', response);
      
      //     if ('errors' in response) {
      //       return response.errors;
      //     }
    
      //     return response;
      //   } catch (error) {
      //     // console.error('Error during query:', error);
      //     throw new Error(error);
      //   }
      // },
      async get_all_Trips(_, { Authtoken, userCompany, startDate, endDate }) {
        try {
          let API_URL = `${this.state.base_URL}trips`;
      
          // Agregar parámetros de fecha si existen
          const queryParams = new URLSearchParams();
          if (startDate) queryParams.append('startDate', startDate);
          if (endDate) queryParams.append('endDate', endDate);
          
          if (queryParams.toString()) {
            API_URL += `?${queryParams.toString()}`;
          }
      
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany
          };
      
          const res = await fetch(API_URL, { method: 'GET', headers });
          const response = await res.json();
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          throw new Error(error);
        }
      },      
      async getTripsInfo(_,{Authtoken, tripID}) {
        try {
          const API_URL = `${this.state.base_URL}trips/${tripID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async update_Trips(_, dataToUpdate) {
        try {

          // console.log('platform_id', dataToUpdate.platform_id);
          const API_URL = `${this.state.base_URL}trips/${dataToUpdate.Trips_id}`;

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }

          formData.append('_method', 'PUT'); // Suplantación del método
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
              // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
            },
            body: formData
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async update_tripStatus(_, { Authtoken, tripId, status }) {
        try {
          const API_URL = `${this.state.base_URL}update/trip-status/${tripId}`;
    
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              trip_status: status
            })
          });
    
          const response = await res.json();
    
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    }
  }