// plugins/AptReservationsPlugin.js

  const state = {
    aptReservations: {
      ArrivalDate: '',
      DepartureDate: '',
      DepartureTime: '',

      pickupDate: '',
      pickupTime: '',
      returnDate: '',
      returnTime: '',
      
      origin: '',
      destination: '',
      serviceType: 1,
      isActivityTransfer: true,
      isDestinationAirport: 0,
      isOriginAirport: 0,
      isArrival: true,
      isDeparture: false,
      arrivalAirline: '',
      arrivalFlightNumber: '',
      arrivalFlightDate: '',
      arrivalFlightTime: '',
      departureAirline: '',
      departureFlightNumber: '',
      departureFlightDate: '',
      departureFlightTime: '',
      adults: 2,
      kids: 0,
      infants: 0,
      vehicles: {
        vehiclePerCompany_id: 0,
        make: '',
        model: '',
        totalAmount: 0,
        Quantity: 0,
      },
      addons: [],
      client: {
        fname: '',
        lname: '',
        email: '',
        phone: '',
      },
      booking_id: '',
      client_id: '',
      status: 0,
      subtotal: 0,
      tax: 0,
      tax_amount: 0,
      total: 0,
      isPrepay: false,
      prepay_amount: 0,
      special_requests: '',
      promoCode: '',
      // ... Cualquier otro campo necesario
    },
  };
  
  const mutations = {
    SET_APT_RESERVATIONS(state, payload) {
      state.aptReservations = { ...state.aptReservations, ...payload };
    },
    CLEAR_APT_RESERVATIONS(state) {
      state.aptReservations = {
        ArrivalDate: '',
        DepartureDate: '',
        DepartureTime: '',

        pickupDate: '',
        pickupTime: '',
        returnDate: '',
        returnTime: '',

        origin: '',
        destination: '',
        serviceType: 1,
        isActivityTransfer: true,
        isDestinationAirport: 0,
        isOriginAirport: 0,
        isArrival: true,
        isDeparture: false,
        arrivalAirline: '',
        arrivalFlightNumber: '',
        arrivalFlightDate: '',
        arrivalFlightTime: '',
        departureAirline: '',
        departureFlightNumber: '',
        departureFlightDate: '',
        departureFlightTime: '',
        adults: 2,
        kids: 0,
        infants: 0,
        vehicles: {
          vehiclePerCompany_id: 0,
          make: '',
          model: '',
          totalAmount: 0,
          Quantity: 0,
        },
        addons: [],
        client: {
          fname: '',
          lname: '',
          email: '',
          phone: '',
        },
        booking_id: '',
        client_id: '',
        status: 0,
        subtotal: 0,
        tax: 0,
        tax_amount: 0,
        total: 0,
        isPrepay: false,
        prepay_amount: 0,
        prepay_due: 0,
        special_requests: '',
        promoCode: '',
        // ... Cualquier otro campo necesario
      };
    },
    

    SET_IS_ARRIVAL(state) {
      state.aptReservations.isArrival = true
      state.aptReservations.isDeparture = false
    },

    SET_IS_DEPARTURE(state) {
      state.aptReservations.isArrival = false
      state.aptReservations.isDeparture = true
      if(this.getAptReservations && !this.getAptReservations.DepartureTime){
        state.aptReservations.DepartureTime = '12:00'
      }
    },

    SET_IS_ROUNDTRIP(state) {
      state.aptReservations.isArrival = true
      state.aptReservations.isDeparture = true
    },
    // ... otras mutaciones que puedas necesitar
  };
  
  const actions = {
    updateAptReservations({ commit }, payload) {
      commit('SET_APT_RESERVATIONS', payload);
    },
    clearAptReservations({ commit }) {
      commit('CLEAR_APT_RESERVATIONS');
    },
    async get_all_aptReservations(_, {Authtoken, userCompany}) {
      try {

        const sessionUser = localStorage.getItem('user');
        const userType = JSON.parse(sessionUser).user.user_type;

        // console.log('userType: ', userType)
        // console.log('userCompany: ', userCompany)
        // console.log('Authtoken', Authtoken);
        // console.log('sessionUser', sessionUser);
        const API_URL = `${this.state.base_URL}apt-reservations`;
        console.log('API_URL: ', API_URL);

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`,
          'User-Company': userCompany,
          'User-Type': userType
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async getAptReservationsInfo(_,{Authtoken, aptResID, userCompany}) {
      // console.log('aptResID: ', aptResID);
      try {
        const API_URL = `${this.state.base_URL}apt-reservations/${aptResID}`;
        // console.log('API_URL on INFO: ', API_URL);
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'User-Company': userCompany,
            'Authorization': `Bearer ${Authtoken}`
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },


    async getRatesInfo(_,{Authtoken, groupID}) {
      try {
        console.log('Received groupID in action:', groupID);
        const API_URL = `${this.state.base_URL}find-rates/${groupID}`;
        console.log('API_URL', API_URL);
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async create_aptReservations(_, {dataToUpdate, token, status}) {
      const API_URL = `${this.state.base_URL}apt-reservations`;
      try {
          const payload = {
              bookingObject: JSON.stringify(dataToUpdate.bookingObject)
          };
  
          const res = await fetch(API_URL, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Reservation-Status': status,
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload)
          });
  
          const response = await res.json();
          // console.log('Response from server:', response);
          return response;
      } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
      }
    },
    async send_reservation_email(_, {booking_id, Authtoken}) {
      try {
        const API_URL = `${this.state.base_URL}send-reservation-email/${booking_id}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
          },
        });
    
        const response = await res.json();
        console.log('Email Response from server:', response);
    
        if ('error' in response) {
          return response.error;
        }
    
        return response;
      } catch (error) {
        console.error('Error sending email:', error);
        throw new Error(error);
      }
    },
    async NewUpdate_aptReservations(_, {dataToUpdate, token, status}) {
      const API_URL = `${this.state.base_URL}upd-apt-reservations/${dataToUpdate.reservationID}`;
      
      try {
          // Crear un objeto FormData
          let formData = new FormData();
  
          // Agregar los datos de bookingObject
          formData.append('bookingObject', JSON.stringify(dataToUpdate.bookingObject));
  
          // Suplantación del método PUT
          formData.append('_method', 'PUT');
  
          const res = await fetch(API_URL, {
              method: 'POST', // Usamos POST, pero con el '_method': 'PUT'
              headers: {
                  'Accept': 'application/json',
                  'Reservation-Status': status,
                  'Authorization': `Bearer ${token}`,
                  // No establecemos 'Content-Type', ya que el navegador lo hará automáticamente con FormData
              },
              body: formData
          });
  
          const response = await res.json();
          
          console.log('Response from server:', response);
  
          if ('errors' in response) {
              return response.errors;
          }
  
          return response;
  
      } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
      }
    },  
    async import_aptReservations(_, { dataToUpdate, token, userCompany }) {
      const API_URL = `${this.state.base_URL}import/apt-reservations`;
      try {
        const payload = {
          reservations: dataToUpdate.bookingObject // Enviar 'reservations' en lugar de 'bookingObject'
        };
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'User-Company': userCompany,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
    
        const response = await res.json();
        console.log('Response from server:', response);
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },    
    async create_aptUnpaidReservations(_, {dataToUpdate, token, status}) {
      const API_URL = `${this.state.base_URL}apt-reservations`;
      try {
          const payload = {
              bookingObject: JSON.stringify(dataToUpdate.bookingObject)
          };

          const res = await fetch(API_URL, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Reservation-Status': status,
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload)
          });

          const response = await res.json();
          console.log('Response from server:', response);
          return response;
      } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
      }
    },
    async update_aptReservations(_, dataToUpdate) {
      try {
        
        // console.log('dataToUpdate.rateID', dataToUpdate.rateID);
        const API_URL = `${this.state.base_URL}apt-reservations/${dataToUpdate.reservationID}`;
        // console.log('API_URL', API_URL);

        // Crear un objeto FormData
        let formData = new FormData();
        
        // Agregar los datos y la imagen al objeto FormData
        for (const key in dataToUpdate) {
          if (key !== 'token' && dataToUpdate[key] !== null) {
            formData.append(key, dataToUpdate[key]);
          }
        }
        formData.append('_method', 'PUT'); // Suplantación del método
  
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`
            // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
          },
          body: formData
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async deactivate_aptReservations(_, { Authtoken, ReservationID }) {
      try {
        
        // console.log('aptResID', aptResID)
        const API_URL = `${this.state.base_URL}update/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            aptRes_status: 2
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async setAsPending_aptReservations(_, { Authtoken, ReservationID }) {
      try {
        
        // console.log('aptResID', aptResID)
        const API_URL = `${this.state.base_URL}update/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            aptRes_status: 4
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async activate_aptReservations(_, { Authtoken, ReservationID }) {
      try {
        
        const API_URL = `${this.state.base_URL}update/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            aptRes_status: 5
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async confirmPayment_aptReservations(_, { Authtoken, ReservationID }) {
      try {
        
        const API_URL = `${this.state.base_URL}update/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            aptRes_status: 1
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async delete_aptReservations(_, { Authtoken, ReservationID }) {
      try {
        
        const API_URL = `${this.state.base_URL}update/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            aptRes_status: 3
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async delete_aptReservations_permanently(_, { Authtoken, rateID }) {
      try {
        
        const API_URL = `${this.state.base_URL}apt-reservations/${rateID}`;
    
        const res = await fetch(API_URL, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },


    async initiate_StripePayment(_, {dataToUpdate, companyID}) {
      try {
        const API_URL = `${this.state.base_URL}payment/initiate`;
        // console.log('JSON.stringify(dataToUpdate)', JSON.stringify(dataToUpdate));
        // console.log('dataToUpdate: ', dataToUpdate);
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'User-Company': companyID,
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Indica que los datos son JSON
          },
          body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
        });
    
        const response = await res.json();
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async create_Client(_, dataToUpdate) {
      try {
        const API_URL = `${this.state.base_URL}clients`;
        // console.log('dataToUpdate', dataToUpdate);
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`,
            'Content-Type': 'application/json', // Indica que los datos son JSON
          },
          body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
        });
    
        const response = await res.json();
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async getPublicAptReservationsInfo(_,aptResID) {
      try {
        const API_URL = `${this.state.base_URL}public/apt-reservations/${aptResID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async activate_PublicAptReservations(_, ReservationID) {
      try {
        
        const API_URL = `${this.state.base_URL}public/apt-reservations/${ReservationID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            aptRes_status: 1
          })
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    // ... todas tus acciones aquí, asegurándote de que cada una esté correctamente cerrada
  };
  
  const getters = {
    getAptReservations(state) {
      return state.aptReservations;
    },
    // ... otros getters que puedas necesitar
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };